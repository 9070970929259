import { Button } from 'antd';
import { ResultConfirm } from 'common/define';
import { useContextBoostrap } from 'context/boostrap.context';
import React from 'react';

const CDialogConfirm = (): JSX.Element => {
  const {
    state: { titleContent },
    dispatch,
  } = useContextBoostrap();
  const { title = 'Confirm', content = '' } = titleContent;

  function handleAction(val: ResultConfirm) {
    dispatch({ type: 'HANDLE_RESULT_CONFIRM', payload: val });
  }

  return (
    <div className="confirm-dialog-yes-no">
      <span className="confirm-dialog-yes-no-title truncate">{title}</span>
      <div
        className="confirm-dialog-yes-no-content truncate"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div className="confirm-dialog-yes-no-footer">
        <Button type="text" onClick={() => handleAction('yes')}>
          Có
        </Button>
        <Button type="text" onClick={() => handleAction('no')}>
          Không
        </Button>
      </div>
    </div>
  );
};

export default CDialogConfirm;
