import { createSelector } from '@reduxjs/toolkit';
import { selectSelfSteelDivide } from './self.selector';

export const getLoadingSteelDivide = createSelector(
    selectSelfSteelDivide,
    (state) => state.loading,
);
export const getAngleBars = createSelector(
    selectSelfSteelDivide,
    (state) => state.angleBars,
);

export const getSelectionAngleBars = createSelector(
    selectSelfSteelDivide,
    (state) => state.selectionAngleBar,
);

export const getResultSteelDivide = createSelector(
    selectSelfSteelDivide,
    (state) => state.result,
);

export const getSelectionLayout = createSelector(
    selectSelfSteelDivide,
    (state) => state.selectionLayout,
);

export const getIsCalculate = createSelector(
    selectSelfSteelDivide,
    (state) => state.isCalculate,
);

export const getCurrentTabActive = createSelector(
    selectSelfSteelDivide,
    (state) => state.currentTabActive,
);

export const getDataImport = createSelector(
    selectSelfSteelDivide,
    (state) => state.dataImport,
);

export const getIsImporting = createSelector(
    selectSelfSteelDivide,
    (state) => state.isImporting,
);

export const getIsExporting = createSelector(
    selectSelfSteelDivide,
    (state) => state.isExporting,
);
