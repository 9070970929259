/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlateResponse } from 'common/define';
import Utils from 'helper/Utils';
import JSEncrypt from 'jsencrypt';
import { map } from 'rxjs/operators';
import { excelFile } from '../common/defineDongkien';
import { SystemConstants } from '../common/system-constants';
import HttpClient from './http-client';
import { APIHosts, ApiReduxHelpers } from './reduxHelpers';

class IdentityApiController extends ApiReduxHelpers {
    ApiHost = APIHosts.Vithep;

    excelFile = (data: excelFile) => {
        const param = Utils.parseUrl(data);
        return HttpClient.upload(
            `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_VITHEP.API.PACKAGE.EXPORT_EXCEL_TO_EXCEL}?${param.toString()}`,
            data.viThepBoq,
            {
                headers: {},
                responseType: 'arraybuffer',
            },
        ).pipe(map((res) => (res as any) || null));
    };

    encryptData(text: string, key: string) {
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(key);
        const encypt = jsEncrypt.encrypt(text);

        return encypt || '';
    }

    getDataFromExcel(data: any) {
        return HttpClient.upload(`${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_VITHEP.API.STEEL_DIVIDE.IMPORT_EXCEL}`, data).pipe(
            map((res) => res as string),
        );
    }

    getResultSteelDivide(data: any) {
        return HttpClient.post(
            `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_VITHEP.API.STEEL_DIVIDE.GET_RESULT
            }`,
            data,
        ).pipe(map((res) => res as string));
    }

    exportReportSteelDivide(data: any) {
        return HttpClient.post(
            `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_VITHEP.API.STEEL_DIVIDE.EXPORT
            }`,
            data,
            {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'arraybuffer',
            },
        ).pipe(map((res) => res as string));
    }

    getResultPlateDivide(data: any) {
        return HttpClient.post(
            `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_VITHEP.API.PLATE_DIVIDE.GET_RESULT
            }`,
            data,
        ).pipe(map((res) => res as PlateResponse[]));
    }
}

export const ViThepApi = new IdentityApiController();
