/* eslint-disable */
class Utils {
  static guidGenerator = () => {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  }
  static setPartialState<T>(state: T, part: Partial<T>): T {
    return { ...state, ...part };
  }
  static jsonEscape = (str: string) => {
    if (!str) return '';
    return str.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
  }
}

export default Utils;