/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViThepApi } from 'api/viThep.api';
import { PlateLayout, PlateResponse, Plates, RootEpic } from 'common/define';
import { merge } from 'rxjs';
import { withLatestFrom, switchMap, filter, catchError } from 'rxjs/operators';
import Utils from 'utils/utils';
import * as FileSaver from 'file-saver';
import { notification } from 'antd';
import { defaultPlateCutting } from './helper/plateCutting.helper';

interface ExportReport {
  report: any,
  nameFile: string,
  vDataRecords: any,
}

export declare type KeyTab = 'plan' | 'stock' | 'request' | 'setting' | 'result';

export interface PlateCuttingState {
  loading: boolean;
  plates: Plates[];
  selectionPlate: Plates | null;
  result: PlateResponse[];
  selectionLayout: any;
  isCalculate: boolean;
  currentTabActive: KeyTab;
  dataImport: any;
  isImporting: boolean;
  isExporting: boolean;
}

const initState = defaultPlateCutting;

export const PlateCuttingSlice = createSlice({
  name: 'plateCutting',
  initialState: initState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPlates(state, action: PayloadAction<Plates[]>) {
      state.plates = action.payload;
    },
    setSelectionPlates(state, action: PayloadAction<Plates | null>) {
      state.selectionPlate = action.payload;
      state.selectionLayout = null;
    },
    setResult(state, action: PayloadAction<PlateResponse[]>) {
      state.result = action.payload;
      if (state.selectionPlate) {
        state.currentTabActive = 'result';
      }
    },
    setSelectionLayout(state, action: PayloadAction<any>) {
      state.selectionLayout = action.payload;
    },
    setIsCalculation(state, action: PayloadAction<boolean>) {
      state.isCalculate = action.payload;
    },
    setCurrentTabActive(state, action: PayloadAction<KeyTab>) {
      state.currentTabActive = action.payload;
    },
    setDataImport(state, action: PayloadAction<any>) {
      state.dataImport = action.payload;
      state.isImporting = false;
    },
    setIsImporting(state, action: PayloadAction<boolean>) {
      state.isImporting = action.payload;
    },
    setIsExporting(state, action: PayloadAction<boolean>) {
      state.isExporting = action.payload;
    },
    clearSteelDivideData(state) {
      state.loading = false;
      state.plates = [];
      state.selectionPlate = null;
      state.result = [];
      state.selectionLayout = null;
      state.isCalculate = false;
      state.currentTabActive = 'plan';
      state.dataImport = null;
      state.isImporting = false;
    },
    getResult(state, action: PayloadAction<Plates[]>) { return; },
    exportReport(state, action: PayloadAction<ExportReport>) { return; },
    getDataFromExcel(state, action: PayloadAction<FormData>) { return; },
  },
});

const notificationResp = (message: string) => {
  notification.open({
    message: 'Thông báo',
    description: message,
    duration: 3,
  });
};

export const getDataFromExcel$: RootEpic = (action$, state$) => action$.pipe(
  filter(getDataFromExcel.match),
  withLatestFrom(state$),
  switchMap(([action, state]) => {
    return merge(
      [setIsImporting(true)],
      ViThepApi.getDataFromExcel(action.payload).pipe(
        switchMap((res: any) => {
          return [setDataImport(res)];
        }),
        catchError((err) => {
          notificationResp('Vui lòng import đúng thông tin file!');
          return [setIsImporting(false)];
        }),
      ),
    );
  }),
);

export const getResult$: RootEpic = (action$, state$) => action$.pipe(
  filter(getResult.match),
  withLatestFrom(state$),
  switchMap(([action, state]) => {
    return merge(
      [setIsCalculation(true)],
      ViThepApi.getResultPlateDivide(action.payload).pipe(
        switchMap((res: PlateResponse[]) => {
          const newData: PlateResponse[] = Object.assign([], res);
          newData.map((item: PlateResponse) => {
            item.message = Utils.jsonEscape(item.message);
            return item;
          });
          return [setResult(newData), setIsCalculation(false)];
        }),
        catchError((err) => {
          notificationResp('Xảy ra lỗi trong quá trình tính toán!');
          return [setIsCalculation(false)];
        }),
      ),
    );
  }),
);

export const exportReport$: RootEpic = (action$, state$) => action$.pipe(
  filter(exportReport.match),
  withLatestFrom(state$),
  switchMap(([action, state]) => {
    const { report, nameFile, vDataRecords } = action.payload;
    return merge(
      [setIsExporting(true)],
      ViThepApi.exportReportSteelDivide({ Results: report, NameFile: nameFile, VDataRecords: vDataRecords }).pipe(
        switchMap((value: any) => {
          const excelFile = new Blob(
            [value],
            { type: 'application/vnd.ms-excel' },
          );
          FileSaver.saveAs(excelFile, `GhepThep_${nameFile ? nameFile : ''}.xls`);
          return [setIsExporting(false)];
        }),
        catchError((err) => [setIsExporting(false)]),
      ),
    );
  }),
);

export const PlateCuttingActionEpics = [getResult$, exportReport$, getDataFromExcel$];

export const {
  setLoading,
  setPlates,
  setSelectionPlates,
  setResult,
  getResult,
  setSelectionLayout,
  exportReport,
  setIsCalculation,
  setCurrentTabActive,
  getDataFromExcel,
  setDataImport,
  setIsImporting,
  clearSteelDivideData,
  setIsExporting,
} = PlateCuttingSlice.actions;

export default PlateCuttingSlice.reducer;
