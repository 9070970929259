import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import 'antd/dist/antd.min.css';
import BootstrapSystem from 'pages/Bootstrap';
import store, { persistor } from './redux/store';
import MainRouter from './pages/router/mainRouter';
import { CThemeProvider } from './theme/Theme';

function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <CThemeProvider>
                    <BootstrapSystem>
                        <MainRouter />
                    </BootstrapSystem>
                </CThemeProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
