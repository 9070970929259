import {
    MaxQuantityPlate, PlateStocks, Point2, Setting,
} from 'common/define';
import { PlateCuttingState } from 'redux/plateCutting.slice';

export const defaultPoint: Point2 = {
    x: 0,
    y: 0,
};

export const defaultPlateCutting: PlateCuttingState = {
    loading: false,
    plates: [],
    selectionPlate: null,
    result: [],
    selectionLayout: null,
    isCalculate: false,
    currentTabActive: 'plan',
    dataImport: null,
    isImporting: false,
    isExporting: false,
};

export const defaultSetting: Setting = {
    minRemnant: 300,
    kerf: 0,
    maxNumberQuantity: MaxQuantityPlate,
    leftTrim: 0,
    rightTrim: 0,
    maxNumberDetails: 4,
};

export const defaultStocks: PlateStocks = {
    point: defaultPoint,
    lx: 6000,
    ly: 1500,
    quantity: MaxQuantityPlate,
    area: 6000 * 1500,
    keyIndex: MaxQuantityPlate,
    suitability: 1,
};
