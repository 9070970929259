/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter, map, switchMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ViThepApi } from '../../api/viThep.api';
import { RootEpic, SystemConfig } from '../../common/define';

interface ConfigState {
    systemConfig: SystemConfig;
    isSuccess: boolean
}
const PATH_SYSTEM_CONFIG = `${process.env.PUBLIC_URL}/assets/system-config.json`;
const IS_CONFIG_LOCAL = false;
const DEFAULT_CONFIG: SystemConfig = {
    protocol: 'http',
    hostViThep: process.env.REACT_APP_HOST_VITHEP || 'https://vithep.hicas.vn',
};
const initialStateConfig: ConfigState = {
    systemConfig: DEFAULT_CONFIG,
    isSuccess: false,
};

function updateHostService(host: SystemConfig) {
    ViThepApi.setConfig(host);
}
const configSlice = createSlice({
    name: 'config',
    initialState: initialStateConfig,
    reducers: {
        setSysytemConfig: (state, action: PayloadAction<SystemConfig>) => {
            updateHostService(action.payload);
            state.systemConfig = action.payload;
            state.isSuccess = true;
        },
        fetchConfig: (state) => {
            state.isSuccess = false;
        },
    },
});

const config$: RootEpic = (action$) => action$.pipe(
    filter(fetchConfig.match),
    switchMap(() => ajax.getJSON(
        PATH_SYSTEM_CONFIG,
        {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    ).pipe(map((res) => {
        const config = IS_CONFIG_LOCAL ? DEFAULT_CONFIG : res as SystemConfig;
        return configSlice.actions.setSysytemConfig(config);
    }))),
);

export const BoostrapEpics = [
    config$,
];

export const { fetchConfig } = configSlice.actions;
export const configReducer = configSlice.reducer;
