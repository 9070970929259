import { createSelector } from '@reduxjs/toolkit';
import { selectSelfPlateCutting } from './self.selector';

export const getLoadingPlateCutting = createSelector(
    selectSelfPlateCutting,
    (state) => state.loading,
);
export const getPlates = createSelector(
    selectSelfPlateCutting,
    (state) => state.plates,
);

export const getSelectionPlates = createSelector(
    selectSelfPlateCutting,
    (state) => state.selectionPlate,
);

export const getResultPlateCutting = createSelector(
    selectSelfPlateCutting,
    (state) => state.result,
);

export const getSelectionLayoutPC = createSelector(
    selectSelfPlateCutting,
    (state) => state.selectionLayout,
);

export const getIsCalculatePC = createSelector(
    selectSelfPlateCutting,
    (state) => state.isCalculate,
);

export const getCurrentTabActivePC = createSelector(
    selectSelfPlateCutting,
    (state) => state.currentTabActive,
);

export const getDataImportPC = createSelector(
    selectSelfPlateCutting,
    (state) => state.dataImport,
);

export const getIsImportingPC = createSelector(
    selectSelfPlateCutting,
    (state) => state.isImporting,
);

export const getIsExportingPC = createSelector(
    selectSelfPlateCutting,
    (state) => state.isExporting,
);
