import { useContextBoostrap } from 'context/boostrap.context';
import React, { useCallback } from 'react';
import CDialog from './CDialog';
import CDialogConfirm from './CDialogConfirm';

export default function CDialogConfirmHandle(): JSX.Element {
    const {
        state: { visibleConfirm },
        dispatch,
    } = useContextBoostrap();

    const handleClose = useCallback(() => {
        dispatch({ type: 'HANDLE_RESULT_CONFIRM', payload: 'cancel' });
    }, [dispatch]);

    return (
        <CDialog
            visible={visibleConfirm}
            onCancel={handleClose}
            content={<CDialogConfirm />}
        />
    );
}
