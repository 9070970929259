import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CLoading from '../../component/router/CLoading';
import { RouterItem } from '../../component/router/CMainRouter';

const TinhThepModule = React.lazy(() => import('../tinhthep/tinhthep'));
const DongKienModule = React.lazy(() => import('../dongkien/dongkien'));
const CatTamModule = React.lazy(() => import('../cattam/cattam'));
const PageNotFound = React.lazy(() => import('../404'));

const RouterArr: RouterItem[] = [
    {
        key: 'tinhthep',
        path: '/tinhthep',
        component: TinhThepModule,
    },
    {
        key: 'dongkien',
        path: '/dongkien',
        component: DongKienModule,
    },
    {
        key: '/tinhtam',
        path: '/tinhtam',
        component: CatTamModule,
    },
    {
        key: 'PageNotFound',
        path: '*',
        component: PageNotFound,
    },
];

export default function MainRouter(): JSX.Element {
    return (
        <Router>
            <Suspense fallback={<CLoading visible={true} fullScreen={true} />}>
                <Routes >
                    {
                        RouterArr.map(
                            ({ path, component: Component, key }) => (
                                <Route path={path} key={key} element={<Component></Component>} />
                            ),
                        )
                    }
                </Routes>
            </Suspense>
        </Router>
    );
}
