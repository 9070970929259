/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViThepApi } from 'api/viThep.api';
import { AngleBars, RootEpic } from 'common/define';
import { merge } from 'rxjs';
import { withLatestFrom, switchMap, filter, catchError } from 'rxjs/operators';
import Utils from 'utils/utils';
import * as FileSaver from 'file-saver';
import { notification } from 'antd';
import { defaultSteelDivide } from './helper/steelDivide.helper';

interface ExportReport {
    report: any,
    nameFile: string,
    vDataRecords: any,
}

export declare type KeyTab = 'plan' | 'stock' | 'request' | 'setting' | 'result';

export interface SteelDivideState {
    loading: boolean;
    angleBars: AngleBars[] | null;
    selectionAngleBar: AngleBars | null;
    result: any;
    selectionLayout: any;
    isCalculate: boolean;
    currentTabActive: KeyTab;
    dataImport: any;
    isImporting: boolean;
    isExporting: boolean;
}

const initState = defaultSteelDivide;

export const steelDivideSlice = createSlice({
    name: 'steelDivide',
    initialState: initState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setAngleBars(state, action: PayloadAction<AngleBars[]>) {
            state.angleBars = action.payload;
        },
        setSelectionAngleBars(state, action: PayloadAction<AngleBars | null>) {
            state.selectionAngleBar = action.payload;
            state.selectionLayout = null;
        },
        setResult(state, action: PayloadAction<any>) {
            state.result = action.payload;
            if (state.selectionAngleBar) {
                state.currentTabActive = 'result';
            }
        },
        setSelectionLayout(state, action: PayloadAction<any>) {
            state.selectionLayout = action.payload;
        },
        setIsCalculation(state, action: PayloadAction<boolean>) {
            state.isCalculate = action.payload;
        },
        setCurrentTabActive(state, action: PayloadAction<KeyTab>) {
            state.currentTabActive = action.payload;
        },
        setDataImport(state, action: PayloadAction<any>) {
            state.dataImport = action.payload;
            state.isImporting = false;
        },
        setIsImporting(state, action: PayloadAction<boolean>) {
            state.isImporting = action.payload;
        },
        setIsExporting(state, action: PayloadAction<boolean>) {
            state.isExporting = action.payload;
        },
        clearSteelDivideData(state) {
            state.loading = false;
            state.angleBars = null;
            state.selectionAngleBar = null;
            state.result = null;
            state.selectionLayout = null;
            state.isCalculate = false;
            state.currentTabActive = 'plan';
            state.dataImport = null;
            state.isImporting = false;
        },
        getResult(state, action: PayloadAction<AngleBars[]>) { return; },
        exportReport(state, action: PayloadAction<ExportReport>) { return; },
        getDataFromExcel(state, action: PayloadAction<FormData>) { return; },
    },
});

const notificationResp = (message: string) => {
    notification.open({
        message: 'Thông báo',
        description: message,
        duration: 3,
    });
};

export const getDataFromExcel$: RootEpic = (action$, state$) => action$.pipe(
    filter(getDataFromExcel.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return merge(
            [setIsImporting(true)],
            ViThepApi.getDataFromExcel(action.payload).pipe(
                switchMap((res: any) => {
                    return [setDataImport(res)];
                }),
                catchError((err) => {
                    notificationResp('Vui lòng import đúng thông tin file!');
                    return [setIsImporting(false)];
                }),
            ),
        );
    }),
);

export const getResult$: RootEpic = (action$, state$) => action$.pipe(
    filter(getResult.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return merge(
            [setIsCalculation(true)],
            ViThepApi.getResultSteelDivide(action.payload).pipe(
                switchMap((res: any) => {
                    const newData = Object.assign([], res);
                    newData.map((item: any) => {
                        item.layouts?.map((layout: any) => {
                            layout.id = Utils.guidGenerator();
                            return layout;
                        });
                        item.message = Utils.jsonEscape(item.message);
                        item.detailMessage = Utils.jsonEscape(item.detailMessage);
                        return item;
                    });
                    return [setResult(newData), setIsCalculation(false)];
                }),
                catchError((err) => {
                    notificationResp('Xảy ra lỗi trong quá trình tính toán!');
                    return [setIsCalculation(false)];
                }),
            ),
        );
    }),
);

export const exportReport$: RootEpic = (action$, state$) => action$.pipe(
    filter(exportReport.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { report, nameFile, vDataRecords } = action.payload;
        return merge(
            [setIsExporting(true)],
            ViThepApi.exportReportSteelDivide({ Results: report, NameFile: nameFile, VDataRecords: vDataRecords }).pipe(
                switchMap((value: any) => {
                    const excelFile = new Blob(
                        [value],
                        { type: 'application/vnd.ms-excel' },
                    );
                    FileSaver.saveAs(excelFile, `GhepThep_${nameFile ? nameFile : ''}.xls`);
                    return [setIsExporting(false)];
                }),
                catchError((err) => [setIsExporting(false)]),
            ),
        );
    }),
);

export const SteelDivideActionEpics = [getResult$, exportReport$, getDataFromExcel$];

export const {
    setLoading,
    setAngleBars,
    setSelectionAngleBars,
    setResult,
    getResult,
    setSelectionLayout,
    exportReport,
    setIsCalculation,
    setCurrentTabActive,
    getDataFromExcel,
    setDataImport,
    setIsImporting,
    clearSteelDivideData,
    setIsExporting,
} = steelDivideSlice.actions;

export default steelDivideSlice.reducer;
