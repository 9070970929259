/* eslint-disable global-require */
/* eslint-disable no-debugger */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import { notification } from 'antd';
import { throwError } from 'rxjs';
import {
    ajax, AjaxError, AjaxRequest, AjaxResponse,
} from 'rxjs/ajax';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, retry } from 'rxjs/operators';
import Utils from '../helper/Utils';

/** types */
type PartAjaxRequest = Omit<
    AjaxRequest,
    | 'url'
    | 'method'
    | 'body'
    | 'async'
    | 'timeout'
    | 'crossDomain'
    | 'withCredentials'
>;
type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PUT';
type HeadersAjax = {
    Authorization: string;
    Accept: string;
    'Content-Type': string;
    'Sec-Fetch-Site'?: string;
};
interface Param {
    url: string;
    data?: unknown;
    headers?: PartAjaxRequest;
}

/** functions */
function mapResponse(res: AjaxResponse<any>) {
    if (res.response) {
        return res.response;
    }
}
async function handleRefreshToken() {
    const refresh_token = Utils.getValueLocalStorage('refresh_token');
    const body = Utils.parseUrl({
        refresh_token: refresh_token,
        client_id: 'Admin',
        grant_type: 'refresh_token',
        scope: 'offline_access API',
    });
    // IdentityApiController.login(body).subscribe((res) => {
    //   console.log(res);

    /*  if (res?.access_token) {
        window.location.reload();
        Utils.setLocalStorage('token', res.access_token);
        Utils.setLocalStorage('refresh_token', res.refresh_token);
      } else {
        document.location.href = '/';
        localStorage.clear();
      } */
    // });
}
function handleError$(err: AjaxError): Observable<unknown> {
    if (err) {
        if (err.status === 401) {
            handleRefreshToken();
        } else if (err.status === 502) {
            // console.log(err.status);
        } else if (err.status === 500) {
            if (err.responseType === 'arraybuffer') {
                const numberArray: number[] = Array.from(new Uint8Array(err.response));
                /* const temp = String.fromCharCode.apply(null, numberArray);
                const errMessage = JSON.parse(temp); */
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const bufferToString = require('arraybuffer-to-string');
                const errMessage = JSON.parse(bufferToString(numberArray));
                notification.warning({ message: errMessage.Message });
            }
        }
    }
    // console.log(err);
    return throwError(err);
}

function mapAjaxRequest(request?: PartAjaxRequest) {
    const token = Utils.getValueLocalStorage('token');
    const mapHeaders = request?.headers
        ? ({ ...request.headers } as HeadersAjax)
        : undefined;
    const customAuth = mapHeaders?.Authorization ? token : `Bearer ${token}`;
    const newHeaders = {
        Authorization: customAuth,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...mapHeaders,
    };
    return { ...request, headers: { ...newHeaders } };
}
function mapResponseHeader(res: AjaxResponse<any>) {
    if (res.response) {
        return res;
    }
}

function commonApiCall(
    method: HttpMethod,
    param: Param,
    isGetHeader = false,
): Observable<unknown> {
    const { url, data, headers } = param;
    const newHeaders = mapAjaxRequest(headers);
    const body = data;
    return ajax({
        url, method, body, ...newHeaders,
    }).pipe(
        map((res: AjaxResponse<any>) => (!isGetHeader ? mapResponse(res) : mapResponseHeader(res))),
        retry(2),
        catchError((err) => handleError$(err)),
    );
}

/** base class */
export default class HttpClient {
    static get(url: string, headers?: PartAjaxRequest): Observable<unknown> {
        return commonApiCall('GET', { url, headers });
    }

    static post(
        url: string,
        data: unknown,
        headers?: PartAjaxRequest,
    ): Observable<unknown> {
        return commonApiCall('POST', { url, data, headers });
    }

    static put(
        url: string,
        data: unknown,
        headers?: PartAjaxRequest,
    ): Observable<unknown> {
        return commonApiCall('PUT', { url, data, headers });
    }

    static delete(url: string, headers?: PartAjaxRequest): Observable<unknown> {
        return commonApiCall('DELETE', { url, headers });
    }

    static upload(
        url: string,
        data: unknown,
        headers?: PartAjaxRequest,
    ): Observable<unknown> {
        const token = Utils.getValueLocalStorage('token');
        const newHeaders = {
            Authorization: token ? `Bearer ${token}` : '',
            ...headers?.headers,
        };
        return ajax({
            url,
            method: 'POST',
            body: data,
            headers: { ...newHeaders },
            ...headers,
        }).pipe(
            map((res) => mapResponse(res)),
            retry(1),
            catchError((err) => handleError$(err)),
        );
    }
}
