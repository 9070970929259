import { AnyAction } from '@reduxjs/toolkit';
import { Epic } from 'redux-observable';
import { RootState } from 'redux/reducers';

export interface SystemConfig {
    protocol: 'http' | 'https';
    hostViThep: string;
}
export type RootEpic = Epic<AnyAction, AnyAction, RootState>;

export interface TitleContentDialog {
    title?: string;
    content?: string;
}

export type ResultConfirm = 'cancel' | 'yes' | 'no';

export interface ContextMenuState {
    visibleConfirm: boolean;
    titleContent: TitleContentDialog;
}

export const NumberMaxQuantity = Number.parseFloat('2e+9');

export const MaxQuantityPlate = Number.parseFloat('2e+6');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BlockInvalidCharInt = (e: any) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

// Steel Devide

export interface AngleBars {
    id?: string;
    type?: string;
    material?: string;
    description?: string;
    stocks: Stocks[];
    parts: Parts[];
    setting?: Setting | null;
    numberCuts?: number | null;
}
export interface Stocks {
    id?: string;
    length: number;
    quantity?: number;
    note: string;
}

export interface Parts {
    id?: string;
    quantity: number;
    quantityOneTower?: number;
    weightOneTower?: number;
    weight?: number;
    member?: string;
    numberKey: string;
    type?: string;
    size?: string;
    length: number | null;
    note?: string;
}

export interface Setting {
    minRemnant?: number;
    maxNumberQuantity?: number;
    leftTrim?: number;
    rightTrim?: number;
    kerf?: number;
    maxNumberDetails?: number;
}

// Plate Cutting

export interface Plates {
    id?: string;
    type: string;
    material?: string;
    description?: string;
    stock: PlateStocks[];
    parts: PlateParts[];
    numberCuts?: number | null;
    setting?: Setting | null;
}

export interface PlateStocks {
    id?: string;
    key?: string;
    point: Point2;
    lx: number;
    ly: number;
    quantity: number;
    area: number;
    keyIndex: number;
    suitability: number;
}

export interface PlateParts {
    id?: string;
    lx: number;
    ly: number;
    mark: string,
    area?: number;
    quantity: number,
}

export interface PlateResponse {
    layouts: PlateLayout[]
    plan: PlatePlan
    message: string
}

export interface PlatePlan {
    stock: PlateStocks[]
    parts: PlateParts[]
    type: string
    description?: string
}

export interface PlateLayout {
    stockRedundancy: PlateStocks[]
    plates: ResPlate[]
    stockOrgin: PlateStocks
    lossRate: number
    optimalSize: string
}

export interface ResPlate {
    point: Point2
    lx: number
    ly: number
    partId: string
    stockKey: string
    area: number
    mark: string
    pointCenter: Point2
    stockKeyIndex: number
}

export interface Point2 {
    x: number;
    y: number;
}

// Print

export interface Size {
    width: number;
    height: number;
}

export interface DText {
    text: string;
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface DRect {
    x: number;
    y: number;
    width: number;
    height: number;
}

//

export enum PlateColors {
    LineStyle = 'dash',
    LineColor = '#9A1909',
    FillStyle = '#FFA500',
    CuttingColor = '#FFFFE0',
    UnCuttingColor = '#FFA500'
}