import CDialogConfirmHandle from 'component/CDialogConfirmHandle';
import { BoostrapContext, useReducerBoostrap } from 'context/boostrap.context';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchConfig } from 'redux/config/config.slice';
import { getBootstrapState } from 'redux/selectors';
import { useSelectorRoot } from 'redux/store';

const BootstrapSystem = ({ children }: Props): JSX.Element | null => {
    const value = useReducerBoostrap();
    const { isSuccess } = useSelectorRoot(getBootstrapState);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchConfig());
    }, []);

    if (!isSuccess) {
        return null;
    }
    return (
        <BoostrapContext.Provider value={value}>
            {children}
            <CDialogConfirmHandle />
        </BoostrapContext.Provider>
    );
};

export default BootstrapSystem;
