import { NumberMaxQuantity, Setting, Stocks } from 'common/define';
import { SteelDivideState } from 'redux/steelDivide.slice';

export const defaultSteelDivide: SteelDivideState = {
    loading: false,
    angleBars: null,
    selectionAngleBar: null,
    result: null,
    selectionLayout: null,
    isCalculate: false,
    currentTabActive: 'plan',
    dataImport: null,
    isImporting: false,
    isExporting: false,
};

export const defaultSetting: Setting = {
    minRemnant: 300,
    kerf: 0,
    maxNumberQuantity: NumberMaxQuantity,
    leftTrim: 0,
    rightTrim: 0,
    maxNumberDetails: 4,
};

export const defaultStocks: Stocks = {
    length: 12000,
    quantity: NumberMaxQuantity,
    note: '',
};
