export class SystemConstants {
    public static PRINT_SCALE = 1;

    public static DPI = { x: 96, y: 96 };

    public static PROTOCOL = 'http';

    public static SERVER_VITHEP = {
        HOST: '',
        API: {
            PACKAGE: {
                EXPORT_JSON_TO_EXCEL: 'api/Package/export',
                EXPORT_EXCEL_TO_EXCEL: 'api/Package/export/excel',
            },
            STEEL_DIVIDE: {
                IMPORT_EXCEL: 'api/SteelDivide/import/excel',
                GET_RESULT: 'api/SteelDivide/Result',
                EXPORT: 'api/SteelDivide/export',
            },
            PLATE_DIVIDE: {
                GET_RESULT: 'api/PlateDivide/Result',
            },
        },
    };
}
