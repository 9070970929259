import { combineReducers } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { BoostrapEpics, configReducer } from './config/config.slice';
import SteelDivideReducer, { SteelDivideActionEpics } from './steelDivide.slice';
import PlateCuttingReducer, { PlateCuttingActionEpics } from './plateCutting.slice';

export const rootReducer = combineReducers({
    bootstrap: configReducer,
    steelDivide: SteelDivideReducer,
    plateCutting: PlateCuttingReducer,
});
export const rootEpic = combineEpics(
    ...BoostrapEpics,
    ...SteelDivideActionEpics,
    ...PlateCuttingActionEpics,
);
export type RootState = ReturnType<typeof rootReducer>;
