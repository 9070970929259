/* eslint-disable no-unused-expressions */
import JSEncrypt from 'jsencrypt';

class Utils {
  static encryptData(text: string, key: string) {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(key);
    const encypt = jsEncrypt.encrypt(text);
    return encypt || '';
  }

  static parseUrl(obj: { [key: string]: string } | any): URLSearchParams {
    const params = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (key && value) {
        params.set(key, value);
      }
    });
    return params;
  }

  static getValueLocalStorage(key: string): any | null {
    const value = localStorage.getItem(key);
    let re = null;
    value && (re = Utils.parseJson(value));
    return re;
  }

  static setLocalStorage(key: string, value:unknown): any | null {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static parseJson(str: string): any | null {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }
}
export default Utils;
