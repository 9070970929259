import { Observable, Subject, take } from 'rxjs';
import { ResultConfirm, TitleContentDialog } from './define';

export class GlobalState {
    static resultConfirm$ = new Subject<ResultConfirm>();

    static confirmDialog$ = new Subject<TitleContentDialog>();

    /** Bootstrap */
    static dispatchConfirmDialog$(prop: TitleContentDialog): Observable<ResultConfirm> {
        GlobalState.confirmDialog$.next(prop);
        return GlobalState.resultConfirm$.pipe(take(1));
    }
    /** End boostrap */
}
